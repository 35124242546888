import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/MdxLayout.tsx";
import SEO from 'src/components/SEO';
import { Tabs, Tab } from '@paljs/ui';
import MdxCard from 'src/components/MdxCard';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Nexus prisma plugin generate types" mdxType="SEO" />
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "nexus-prisma-plugin-generate-types"
      }}>{`Nexus prisma plugin generate types`}</h2>
      <p>{`This is Cli tool to Create CRUD system for `}<a parentName="p" {...{
          "href": "https://nexusjs.org/docs/pluginss/prisma/overview"
        }}>{`Nexus Prisma plugin`}</a>{` from your `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`schema.prisma`}</code>{` file`}</p>
      <p><strong parentName="p"><em parentName="strong">{`CONTENT`}</em></strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#install"
          }}>{`Install`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#configurations"
          }}>{`Configurations`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#output"
          }}>{`OutPut`}</a></li>
      </ul>
    </MdxCard>
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "install"
      }}>{`Install`}</h2>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "shell"
      }}><pre parentName="div" {...{
          "className": "language-shell"
        }}><code parentName="pre" {...{
            "className": "language-shell"
          }}><span parentName="code" {...{
              "className": "token function"
            }}>{`yarn`}</span>{` global `}<span parentName="code" {...{
              "className": "token function"
            }}>{`add`}</span>{` @paljs/cli
//or
`}<span parentName="code" {...{
              "className": "token function"
            }}>{`npm`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`install`}</span>{` -g @paljs/cli`}</code></pre></div>
      <h3 {...{
        "id": "configurations"
      }}>{`Configurations`}</h3>
      <p>{`Create new file with `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`pal.js`}</code>{` name and add this content:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "js"
      }}><pre parentName="div" {...{
          "className": "language-js"
        }}><code parentName="pre" {...{
            "className": "language-js"
          }}>{`module`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`exports `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  backend`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    generator`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'nexus-plugin-prisma'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <p><a parentName="p" {...{
          "href": "/cli/generator#config-file"
        }}>{`Here is all options in `}<code parentName="a" {...{
            "className": "language-text"
          }}>{`pal.js`}</code>{` file `}</a></p>
    </MdxCard>
    <MdxCard mdxType="MdxCard">
      <h3 {...{
        "id": "example"
      }}>{`Example`}</h3>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "prisma"
      }}><pre parentName="div" {...{
          "className": "language-prisma"
        }}><code parentName="pre" {...{
            "className": "language-prisma"
          }}><span parentName="code" {...{
              "className": "token comment"
            }}>{`// schema.prisma`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`datasource`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` postgresql`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  url      `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`env`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`"DATABASE_URL"`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  provider `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`"postgresql"`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`generator`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` client`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  provider `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`"prisma-client-js"`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`model`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` User`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  id       `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` String`}</span>{`   `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@id`}</span>{` `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`cuid`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  email    `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` String`}</span>{`   `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@unique`}</span>{`
  birthDate`}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` DateTime`}</span>{`
  posts    `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Post`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`model`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` Post`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  id    `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` String`}</span>{` `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@id`}</span>{` `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`cuid`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  author`}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` User`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre></div>
      <p>{`For generate all types you need to run `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`pal g`}</code>{` command for all options `}<a parentName="p" {...{
          "href": "/cli/generator"
        }}>{`click here`}</a></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "shell"
      }}><pre parentName="div" {...{
          "className": "language-shell"
        }}><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`pal g`}</code></pre></div>
      <h3 {...{
        "id": "output"
      }}>{`OutPut`}</h3>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token comment"
            }}>{`// User model`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` objectType`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` arg`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` extendType `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@nexus/schema'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` User `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`objectType`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  name`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'User'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token function"
            }}>{`definition`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`t`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    t`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`model`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`id`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    t`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`model`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`email`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    t`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`model`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`birthDate`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    t`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`model`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`posts`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` userQuery `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`extendType`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`type`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Query'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token function"
            }}>{`definition`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`t`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    t`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`crud`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`user`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    t`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`field`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'findFirstUser'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
      `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`type`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'User'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
      args`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
        where`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'UserWhereInput'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
        orderBy`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`arg`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`type`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'UserOrderByInput'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` list`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token boolean"
            }}>{`true`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
        cursor`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'UserWhereUniqueInput'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
        skip`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Int'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
        take`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Int'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
      `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
      `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`async`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`resolve`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`_root`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` args`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` ctx`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
        `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` ctx`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`prisma`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`user`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`findFirst`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`args`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
      `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    t`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`crud`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`users`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` filtering`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token boolean"
            }}>{`true`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` ordering`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token boolean"
            }}>{`true`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    t`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`field`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'usersCount'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
      `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`type`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Int'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
      args`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
        where`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'UserWhereInput'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
      `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
      `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`async`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`resolve`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`_root`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` args`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` ctx`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
        `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` ctx`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`prisma`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`user`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`count`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`args`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
      `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` userMutation `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`extendType`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`type`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Mutation'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token function"
            }}>{`definition`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`t`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    t`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`crud`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`createOneUser`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    t`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`crud`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`updateOneUser`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    t`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`crud`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`upsertOneUser`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    t`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`crud`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`deleteOneUser`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    t`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`crud`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`updateManyUser`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    t`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`crud`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`deleteManyUser`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
    </MdxCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      